import React, { Component } from 'react'
import { connect } from 'react-redux'
import detectBrowserLanguage from 'detect-browser-language'

import { SocketContext, socket } from "./SocketContext"

const values = {
  global : {
    click : {
      "fr": "Clique ici",
      "en" : "Click here",
    },
    error : {
      "fr": "Une erreur est survenue, contactez-nous",
      "en" : "An error has occurred, contact us",
    },
  },
  logout : {
    title : {
      "fr": "Déconnexion",
      "en" : "Logout",
    },
  },
  login : { //login page
    title : {
      "fr": "Connexion",
      "en" : "Login",
    },
    emailField : {
      "fr" : "Votre email",
      "en" : "Your email",
    },
    passwordField : {
      "fr" : "Mot de passe",
      "en" : "Your password",
    },
    formValid : {
      "fr" : "Connexion",
      "en" : "Login",
    },
    createAccount : {
      "fr" : "Pas de compte ?",
      "en" : "No account ?",
    },
    forgotPassword : {
      "fr" : "Mot de passe oublié",
      "en" : "Forgotten password",
    },
    eduServ : {
      "fr" : "Se connecter avec EduServices",
      "en" : "Login with EduServices",
    },
  },
  errorLogin : { //errorLogin page
    title : {
      "fr": "Une erreur est survenue",
      "en" : "An error has occurred",
    },
    texte : {
      "fr" : "Nous sommes désolés mais votre compte n’existe pas. Rapprochez vous de votre école pour leur signaler ce problème et qu’il puisse créer votre compte rapidement.",
      "en" : "We're sorry but your account does not exist. Contact your school to report this problem to them so they can create your account quickly.",
    },
    button : {
      "fr" : "Retour",
      "en" : "Back",
    },
  },
  forgotPassword : {//forgotPassword page
    title : {
      "fr" : "Mot de passe oublié",
      "en" : "Forgotten password",
    },
    emailField : {
      "fr" : "Votre email",
      "en" : "Your email",
    },
    formValid : {
      "fr" : "Réinitialiser",
      "en" : "Reset",
    },
    buttonMenu : {
      "fr" : "Connexion",
      "en" : "Login",
    },
    messageEnd : {
      "fr" : "Un email vous a été envoyé avec votre nouveau mot de passe.",
      "en" : "An email has been sent to you with your new password.",
    },
  },
  register : {//register page
    formValid : {
      "fr" : "Continuer",
      "en" : "Continue",
    },
    alreadyAccount : {
      "fr" : "Déjà un compte ?",
      "en" : "Already have an account ?",
    },
    nameField : {
      "fr" : "Votre nom",
      "en" : "Your name",
    },
    firstNameField : {
      "fr" : "Votre prénom",
      "en" : "Your first name",
    },
    lastNameField : {
      "fr" : "Votre nom",
      "en" : "Your last name",
    },
    emailField : {
      "fr" : "Votre email",
      "en" : "Your email",
    },
    passwordField : {
      "fr" : "Mot de passe",
      "en" : "Your password",
    },
    language : {
      title : {
        "fr" : "Choisissez votre langue",
        "en" : "Choose your language",
      },
      subTitle : {
        "fr" : "Parce qu’elles étudient notre cognition ou en d’autre terme notre esprit.",
        "en" : "Because they study our cognition or in other words our mind.",
      },
    },
    code : {
      title : {
        "fr" : "Entrez le code groupe",
        "en" : "Enter group code",
      },
      field : {
        "fr" : "Votre code",
        "en" : "Your code",
      },
    },
    infos : {
      title : {
        "fr" : "Entrez vos informations",
        "en" : "Enter your information",
      },
      subTitle : {
        "fr" : "Parce qu’elles étudient notre cognition ou en d’autre terme notre esprit.",
        "en" : "Because they study our cognition or in other words our mind.",
      },
      ruTexte : {
        "fr" : "J'accepte les ",
        "en" : "I agree to the ",
      },
      ruTitle : {
        "fr" : "conditions générales d'utilisation",
        "en"  : "terms of use"
      },
      verif : {
        title : {
          "fr" : "Vérifiez vos informations",
          "en" : "Check your information",
        },
        subTitle : {
          "fr" : "Tout changement ne sera plus possible dès lors que vous cliquez sur “Continuer”",
          "en" : "Any changes will no longer be possible once you click “Continue”",
        },
        closeText : {
          "fr" : "Retour",
          "en" : "Go back",
        },
        validText : {
          "fr" : "Continuer",
          "en" : "Continue",
        },
      },
    },
    formValid2 : {
      "fr" : "Inscription",
      "en" : "Register",
    },
    formValidUpdate : {
      "fr" : "Sauvegarder",
      "en" : "Save",
    },
  },
  dashboardMenu : {
    myAccount : {
      "fr" : "Mon Compte",
      "en" : "My Account",
    },
    help : {
      "fr" : "Centre d'Aide",
      "en" : "Help Center",
    },
    helpTitle : {
      "fr" : "Des difficultés ?",
      "en" : "Having troubles ?",
    },
    helpSubTitle : {
      "fr" : "Nous sommes là pour vous aider, cliquez ici",
      "en" : "We're here to assist you, click here",
    },
    logOut : {
      "fr" : "Déconnexion",
      "en" : "Logout",
    },
    backToDashboard : {
      "fr" : "Revenir au dashboard",
      "en" : "Go back to dashboard",
    },
  },
  onBoarding : {
    sexe : {
      "fr" : "Sexe",
      "en" : "Sexe",
    },
    age : {
      "fr" : "Age",
      "en" : "Age",
    },
    poste : {
      "fr" : "Nom entreprise/école",
      "en" : "Company/school name",
    },
    q2Rep1 : {
      "fr" : "Une femme",
      "en" : "A woman",
    },
    q2Rep2 : {
      "fr" : "Un homme",
      "en" : "A man",
    },
  },
  dashboard : {
    seeMore : {
      "fr" : "Voir plus",
      "en" : "See more",
    },
    download : {
      "fr" : "Télécharger",
      "en" : "Download",
    },
    shareResult : {
      "fr" : "Partage tes résultats",
      "en" : "Share your results",
    },
    welcomeTitle : {
      "fr" : "Bonjour ",
      "en" : "Hello ",
    },
    welcomeText : {
      "fr" : " effectuez le suivi de vos formations. Lancez-vous dès maintenant !",
      "en" : " follow up on your trainings. Get started now !",
    },
  },
  dashCarousel : {
    expertTitle : {
      "fr" : "Votre expert :",
      "en" : "Your expert :",
    },
    expertDesc : {
      "fr" : "Docteur en Neurosciences",
      "en" : "Doctor in Neurosciences",
    },
    progressText : {
      "fr" : "complété",
      "en" : "completed",
    },
    disponibleTo : {
      "fr" : "Disponible jusqu’au :",
      "en" : "Available until :",
    },
    disponible : {
      "fr" : "Disponible à partir du :",
      "en" : "Available from :",
    },
    closed : {
      "fr" : "Fermé depuis le :",
      "en" : "Closed since :",
    },
    daysLeft : {
      "fr" : "jours restants",
      "en" : "days left",
    },
    dayLeft : {
      "fr" : "jour restant",
      "en" : "day left",
    },
    button : {
      notify : {
        "fr" : "Me notifier",
        "en" : "Notify me",
      },
      results : {
        "fr" : "Voir résultats",
        "en" : "See results",
      },
      start : {
        "fr" : "Commencer",
        "en" : "Start",
      },
      resume : {
        "fr" : "Reprendre",
        "en" : "Resume",
      }
    },
  },
  dashStepper : {
    completed : {
      "fr" : "Complétée",
      "en" : "Completed",
    },
    inProgress : {
      "fr" : "En Cours",
      "en" : "In Progress",
    },
    closed : {
      "fr" : "Fermée",
      "en" : "Closed",
    },
    available : {
      "fr" : "Disponible",
      "en" : "Available",
    },
  },
  formation : {
    screenOrientationTitle : {
      "fr" : "Orientation de l'écran",
      "en" : "Screen orientation",
    },
    screenOrientationText : {
      "fr" : "Pour une meilleure expérience, nous vous conseillons d'utiliser votre appareil en mode paysage",
      "en" : "For a better experience, we advise you to use your device in landscape mode",
    },
    screenOrientationTextMobile : {
      "fr" : "Pour une meilleure expérience, nous vous conseillons d'utiliser votre appareil en mode portrait",
      "en" : "For a better experience, we advise you to use your device in portrait mode",
    },
    screenOrientationBtn : {
      "fr" : "Fermer",
      "en" : "Close",
    },
  },
  formatVideo : {
    resume : {
      "fr" : "Reprendre",
      "en" : "Resume",
    },
  },
  formatInscription : {
    nameFieldTitle : {
      "fr" : "Votre prénom",
      "en" : "Your firstname",
    },
    nameFieldPlaceholder : {
      "fr" : "Prénom",
      "en" : "Firstname",
    },
    nameFieldError : {
      "fr" : "Prénom invalide",
      "en" : "Invalid firstname",
    },
    emailFieldTitle : {
      "fr" : "Votre email",
      "en" : "Your email",
    },
    emailFieldError : {
      "fr" : "Email invalide",
      "en" : "Invalid email",
    },
  },
  formatDiagnostic : {
    loadingScore : {
      "fr" : "Nous sommes en train d’analyser vos résultats",
      "en" : "We are analyzing your results",
    },
  },
  landingScore : {
    score : {
      "fr" : "Score de",
      "en" : "Score of",
    },
    sharing : {
      "fr" : "Challengez vos amis et voyez qui est le meilleur d’entre vous.",
      "en" : "Challenge your friends and see who is the best of you.",
    },
  },
  adminDashboard : {
    welcomeTitle : {
      "fr" : "Bonjour ",
      "en" : "Hello ",
    },
    welcomeText : {
      "fr" : "Bienvenue dans votre dashboard personnalisé. Vous allez pouvoir suivre en temps réel l’état d’avancement de vos groupes.",
      "en" : "Welcome to your personal dashboard. You can track your groups progress in real-time.",
    },
    watchTour : {
      "fr" : "Voir Tutoriel",
      "en" : "Watch Tour",
    },
    monitoring : {
      "fr" : "Suivi",
      "en" : "Monitoring",
    },
    monitoringSub : {
      "fr" : "Suivez en temps réel les résultats de votre groupe.",
      "en" : "Track your group's results in real-time."
    },
    mapping : {
      "fr" : "Mapping",
      "en" : "Mapping",
    },
    mappingSub : {
      "fr" : "Le mapping par secteur, par année de votre groupe.",
      "en" : "Map your group by sector and by year."
    },
    certification : {
      "fr" : "Certification",
      "en" : "Certification",
    },
    certificationSub : {
      "fr" : "La certification soft skills de votre diplôme.",
      "en" : "The soft skills certification for your diploma."
    },
    usedSoftSkills : {
      "fr" : "Soft Skills Utilisés",
      "en" : "Applied Soft Skills",
    },
    efficaciteSkills : {
      "fr" : "Efficacité opérationnelle",
      "en" : "Operational effectiveness",
    },
    communicationSkills : {
      "fr" : "Communication et innovation",
      "en" : "Communication and innovation",
    },
    leadershipSkills : {
      "fr" : "Leadership et collaboration",
      "en" : "Leadership and collaboration",
    },
    exportTable : {
      "fr" : "Exporter le tableau",
      "en" : "Export the data",
    },
    completionChart : {
      title : {
        "fr" : "Total étudiants ayant fini…",
        "en" : "Total students who finished…",
      },
      average : {
        "fr" : "Total",
        "en" : "Total",
      },
    },
    monitoringCards : {
      students : {
        "fr" : "Étudiants total",
        "en" : "Total students",
      },
      modules : {
        "fr" : "Modules",
        "en" : "Modules",
      },
      completion : {
        "fr" : "Taux de complétion",
        "en" : "Completion rate",
      },
      average : {
        "fr" : "Moyenne groupe",
        "en" : "Group average",
      },
      softSkills : {
        "fr" : "Soft Skills",
        "en" : "Soft Skills",
      },
      time : {
        "fr" : "Moyenne temps passé",
        "en" : "Average time spent",
      }
    },
    monitoringTable : {
      name : {
        "fr" : "Nom",
        "en" : "Name",
      },
      module : {
        "fr" : "Module",
        "en" : "Module",
      },
      score : {
        "fr" : "Status",
        "en" : "Status",
      },
      moduleType : {
        "fr" : "Type de module",
        "en" : "Module type",
      },
      sector : {
        "fr" : "Secteur",
        "en" : "Sector",
      },
      studyYear : {
        "fr" : "Année d'étude",
        "en" : "Year of study",
      },
      graduationYear : {
        "fr" : "Année de diplôme",
        "en" : "Graduation year",
      },
      statusProgress : {
        "fr" : "Complété à ",
        "en" : "Completed at "
      },
      statusNotStarted : {
        "fr" : "Pas commencé",
        "en" : "Not started"
      },
    },
    mappingCards : {
      students : {
        "fr" : "Nombre d’étudiants",
        "en" : "Number of students",
      },
      sectors : {
        "fr" : "Nombre de secteurs",
        "en" : "Number of sectors",
      },
      softSkills : {
        "fr" : "Nombre de Soft Skills",
        "en" : "Number of Soft Skills",
      },
      date : {
        "fr" : "En date du",
        "en" : "Date",
      },
    },
    mappingCanvas : {
      title : {
        "fr" : "Niveau de connaissances en core skills",
        "en" : "Knowledge level in core skills",
      },
      textG : {
        "fr" : "Avant la formation",
        "en" : "Before the training",
      },
      textD : {
        "fr" : "Après la formation",
        "en" : "After the training",
      },
      pdfBefore : {
        "fr" : "Avant",
        "en" : "Before",
      },
      pdfAfter : {
        "fr" : "Après",
        "en" : "After",
      },
      formation : {
        "fr" : "la formation",
        "en" : "the training",
      },
    },
    filters : {
      addFilter : {
        "fr" : "Ajouter Filtre",
        "en" : "Add Filter",
      },
      deleteAll : {
        "fr" : "Tout supprimer",
        "en" : "Remove all",
      },
      delete : {
        "fr" : "Vider",
        "en" : "Clear",
      },
      moduleName : {
        "fr" : "Nom du module",
        "en" : "Module name",
      },
      moduleNameSub : {
        "fr" : "Formations",
        "en" : "Training",
      },
      moduleType : {
        "fr" : "Type de module",
        "en" : "Module type",
      },
      moduleTypeSub : {
        "fr" : "Types",
        "en" : "Types",
      },
      studyYear : {
        "fr" : "Année d'étude",
        "en" : "Year of study",
      },
      studyYearSub : {
        "fr" : "Années d'études",
        "en" : "Years of study",
      },
      graduationYear : {
        "fr" : "Année de diplôme",
        "en" : "Graduation year",
      },
      graduationYearSub : {
        "fr" : "Années de diplôme",
        "en" : "Graduation years",
      },
      sector : {
        "fr" : "Secteur",
        "en" : "Sector",
      },
      sectorSub : {
        "fr" : "Secteurs",
        "en" : "Sectors",
      },
      status : {
        "fr" : "Status",
        "en" : "Status",
      },
      statusSub : {
        "fr" : "Status",
        "en" : "Status",
      },
    },
    contentType : {
      identif : {
        "fr" : "Évaluation",
        "en" : "Evaluation",
      },
      enrich : {
        "fr" : "Enrichissement",
        "en" : "Enrichment",
      }
    },
    statusTypes : {
      notStarted : {
        "fr" : "Pas commencé",
        "en" : "Not started",
      },
      inProgress : {
        "fr" : "En cours",
        "en" : "In progress",
      },
      ended : {
        "fr" : "Terminé",
        "en" : "Finished",
      }
    },
    blockModal : {
      title : {
        "fr" : "Débloquez la fonctionnalité",
        "en" : "Upgrade to",
      },
      feature : {
        "fr" : "Fonctionnalité",
        "en" : "Feature",
      },
      subTitle : {
        mapping : {
          "fr" : "Le Mapping soft skills de votre groupe",
          "en" : "The soft skills mapping of your group",
        },
        certification : {
          "fr" : "La certification soft skills de votre diplôme",
          "en" : "The soft skills certification for your diploma",
        },
      },
      card1 : {
        mapping : {
          "fr" : "Découvrez les progrès des étudiants sur leurs connaissances en soft skills",
          "en" : "Discover students’ progress on their soft skills knowledge.",
        },
        certification : {
          "fr" : "Accédez à vos certifications soft skills par diplôme et par année.",
          "en" : "Access your soft skills certifications by degree and by year.",
        },
      },
      card2 : {
        mapping : {
          "fr" : "Cartographier les soft skills de vos étudiants par secteur et année d’études.",
          "en" : "Map your students' soft skills by sector and by the year of study.",
        },
        certification : {
          "fr" : "Retrouvez vos open badges par soft skills et par diplôme.",
          "en" : "Find your open badges with the help of the soft skills and degree.",
        },
      },
      button : {
        "fr" : "Contactez les commerciaux",
        "en" : "Contact Sales Team",
      },
    },
  },
}

const defaultState = {
  language : null,
  isLoading : false,
  values : values,
  changeLanguage: () => {},
}

const LanguageContext = React.createContext(defaultState)

class LanguageProvider extends Component {

  state = {
    language : null,
    isLoading : false,
    values : values,
  }

  changeLanguage = (lang) => {
    this.setState({
      language : lang
    })
  }



  componentDidMount() {
    let detectLanguage = detectBrowserLanguage()
    let language = "en"
    if (detectLanguage.includes("fr-")) {
      language = "fr"
    }
    this.setState({
      language : language,
      isLoading : true
    });

  }

  componentWillUpdate = (nextProps) => {
    if (this.props.auth.isAuthenticated === true) {
      if (this.props.user.language) {
        if (this.props.user.language !== this.state.language) {
          this.setState({
            language : this.props.user.language,
            isLoading : true
          });
        }
      }
    } else {
      let detectLanguage = detectBrowserLanguage()
      let language = "en"
      if (detectLanguage.includes("fr-")) {
        language = "fr"
      }
      if (language !== this.state.language) {
        this.setState({
          language : language,
          isLoading : true
        });
      }
    }
  }


  render() {
    const { children } = this.props
    return (
      <>
        {this.props.auth.isAuthenticated === true ? (
          <SocketContext.Provider value={socket}>
            <LanguageContext.Provider
              value={{
                language: this.state.language,
                isLoading: this.state.isLoading,
                values : this.state.values,
                changeLanguage : this.changeLanguage,
              }}
            >
              {children}
            </LanguageContext.Provider>
          </SocketContext.Provider>
        ) : (
          <LanguageContext.Provider
            value={{
              language: this.state.language,
              isLoading: this.state.isLoading,
              values : this.state.values,
              changeLanguage : this.changeLanguage,
            }}
          >
            {children}
          </LanguageContext.Provider>
        )}
      </>
  )
  }
}

const mapStateToProps = (state) => {
  return {
    auth : state.auth,
    user : state.user,
  }
}

export default LanguageContext

const LanguageProvider2 = connect(
  mapStateToProps
)(LanguageProvider)

export { LanguageProvider2 }
