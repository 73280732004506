import Format from './Format.js'
import MaskQuizz from '../helpers/MaskQuizz.js'
import Button from '../Button'
import React, { Component } from 'react'

import EditableField from '../helpers/EditableField.js'
import EditableArea from '../helpers/EditableArea.js'
import DiagnosticDropdown from '../../../admin/components/dropdown/DiagnosticDropdown.js'
import { getPointsByQuizz } from '../../../utils/PlayerFn.js'
import DonutV3 from '../helpers/DonutV3.js'
import updateFormation from '../../../utils/updateFormation.js'

import LanguageContext from "../../../contexts/LanguageContext"

class FormatDiagnostic extends Format {
  constructor(props) {
    super(props)
    var userResult = props.format.userChoice ?? this.getUserResult()

    this.getUserResult()

    this.state = {
      viewResult : this.props.isEditor === true ? true : false,
      userResult: userResult,
      isMobile: window.innerWidth <= 600 ? true : false,
      stepIndex: this.getStepIndex(userResult.master.score, this.props.format.total),
      categoriesIndex: this.getCategoriesIndex(userResult, props.format.categorie),
    }

    setTimeout(() => {
      this.setState({viewResult : true})
    }, 4000);

  }

  updateIsMobile() {
    this.setState(() => ({ isMobile: window.innerWidth <= 600 }))
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateIsMobile.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateIsMobile.bind(this))
  }


  getUserResult = () => {
    let categories = {master: { points: 0, max: 0, score: 0 }}
    if (this.props.isLanding) {
    } else {
      if (this.props.format && this.props.format.isChapter && this.props.allFormation) {
        categories.glbMaster = { points: 0, max: 0, score: 0 }
      }
    }
    const manageFormat = (format) => {
      if (['Quizz', 'QuestionnaireV2'].includes(format.type )) {
        const [points, max] = getPointsByQuizz(format)
        //const makeResult = (o) => ({ ...o, max: o.max + max, points: o.points + points, score: o.max > 0 ? Math.round((o.points / o.max) * 100) : 100 })
        const makeResult = (c, points, max) => {
          let newPoints = c.points+points
          let newMax = c.max+max
          return {
            points : newPoints,
            max : newMax,
            score : Math.round((newPoints / newMax) * 100)
          }
        }
        categories.master = makeResult(categories.master, points, max)
        if (format.categorie && format.categorie !== 'master') {
          categories[format.categorie] = makeResult(categories[format.categorie] ?? { points: 0, max: 0, score: 0  }, points, max)
        }
      }
    }

    let glbMasterPond = []
    if (this.props.allFormation.glbMasterPond !== null) {
      glbMasterPond = JSON.parse(this.props.allFormation.glbMasterPond)
    }
    const manageFormatGlb = (format) => {
      if (['Quizz', 'QuestionnaireV2'].includes(format.type )) {
        const [points, max] = getPointsByQuizz(format)
        //const makeResult = (o) => ({ ...o, max: o.max + max, points: o.points + points, score: o.max > 0 ? Math.round((o.points / o.max) * 100) : 100 })
        const makeResult = (c, points, max) => {
          //on cherche si la categorie est à ignorer ou non
          let statut = "oui"
          let find = glbMasterPond.find((el) => el.key === format.categorie)
          if (find) {
            statut = find.value
          }
          if (statut === "oui") {
            let newPoints = c.points+points
            let newMax = c.max+max
            return {
              points : newPoints,
              max : newMax,
              score : Math.round((newPoints / newMax) * 100)
            }
          } else {
            return {
              points : c.points,
              max : c.max,
              score : Math.round((c.points / c.max) * 100),
            }
          }
        }
        categories.glbMaster = makeResult(categories.glbMaster, points, max)
      }
    }

    if (this.props.isLanding) {
      //landing
      for (var i = this.props.index - 1; i >= 0; i--) {
        manageFormat(this.props.userContent[i]);
      }
    } else {
      //formation
      if (this.props.format.isChapter) {
        //master glb
        if (this.props.allFormation) {
          for (var c in this.props.allFormation.chapitre) {
            for (var i in this.props.allFormation.chapitre[c].partie) {
              for (var n in this.props.allFormation.chapitre[c].partie[i].sequence) {
                manageFormatGlb(this.props.allFormation.chapitre[c].partie[i].sequence[n])
              }
            }
          }
        }
        //master + autres catégories
        const parts = this.props.chapitre.partie
        for (var i in parts) {
          for (var n in parts[i].sequence) {
            manageFormat(parts[i].sequence[n])
          }
        }
      } else {
        for (var i = this.props.index - 1; i >= 0; i--) {
          manageFormat(this.props.userContent[i])
        }
      }
    }

    this.props.onDataChange([{ key: 'userChoice', value: categories }])

    return categories
  }

  getStepIndex = (score, seuils) =>{
    var iSeuil = 0;
    var maxSeuil = 0;
    for(var i =0; i < seuils.length; i++ ){
      const step = seuils[i]
      if(score >= step.seuil && step.seuil >= maxSeuil){
        maxSeuil = step.seuil
        iSeuil = i
      }
    }
    return iSeuil
  }

  getCategoriesIndex = (categories, selectedCategories) =>{
    const categoriesIndex={}
    for(var cat of selectedCategories){
      categoriesIndex[cat.key] = categories[cat.key] ? this.getStepIndex(categories[cat.key].score, cat.seuils) : 0
    }
    return categoriesIndex;
  }

  handleStepsChange = (value, key, i) => {
    var data = this.props.format.total
    data[i][key] = value
    return { data: data, index: false }
  }

  handleCategoryStepsChange = (i, value, key) => {
    var data = this.props.format.categorie
    data[i][key] = value
    data.sort((a,b)=>(a.position - b.position))
    return { data: data, index: false }
  }

  handleCategorySeuilChange = (iCat, iSeuil, value, key) => {
    var data = this.props.format.categorie
    data[iCat].seuils[iSeuil][key] = value
    return { data: data, index: false }
  }

  render() {

    let categorieByScore = JSON.parse(JSON.stringify(this.props.format.categorie))
    if (this.state.userResult) {
      categorieByScore.map((cat, i) => {
        // on filtre
        let userResult = this.state.userResult[cat.key] ?? {score: 0, points: 0, max : 0}
        let seuil = cat.seuils[this.state.categoriesIndex[cat.key]] ?? {seuil: 0, text: null}
        cat.score = userResult.score;
      })

      //on sort les catégories master et glbMaster du trie
      let masterCats = []
      let findMaster = categorieByScore.find((el) => el.key === "master")
      if (findMaster) {
        masterCats.push(findMaster)
      }
      let findGlbMaster = categorieByScore.find((el) => el.key === "glbMaster")
      if (findGlbMaster) {
        masterCats.push(findGlbMaster)
      }

      //on supprimer les catégories master et glbMaster du premier tableau
      categorieByScore = categorieByScore.filter((el) => el.key !== "master")
      categorieByScore = categorieByScore.filter((el) => el.key !== "glbMaster")

      categorieByScore.sort((a,b) => b.score - a.score);

      categorieByScore = masterCats.concat(categorieByScore)

    }


    const maskQuizz =
        <MaskQuizz className="maskQuizz">
        {categorieByScore.map((cat, i) => {
          var userResult = this.state.userResult[cat.key] ?? {score: 0, points: 0, max : 0}
          var seuil = cat.seuils[this.state.categoriesIndex[cat.key]] ?? {seuil: 0, text: null}
          let dureeAnim = 2 //secondes
          let delayAnim = (i+1)*2.5 //delay anim en secondes
          let totalAnim = delayAnim;
          if (this.props.isEditor === true) {
            dureeAnim = 0;
            delayAnim = 0;
            totalAnim = 0;
          }

          return (
            <div  className="diag_donut animOpacity0" key={cat.key} style={{
              animation : dureeAnim+"s linear "+delayAnim+"s fadeIn"
            }}>
              <DonutV3
                title={cat.key}
                score={userResult.score}
                name={`donut_${cat.key}`}
                totalAnim={totalAnim}
              />
              <div className="diag_donut_detail">
                <EditableField
                  isLanding={this.props.isLanding}
                  tag="h4"
                  field={cat.title ?? cat.key}
                  isEditor={this.props.isEditor}
                  type="categories"
                  key={'categoriesTitle_'+ cat.key}
                  onInputChange={(title) => { return this.handleCategoryStepsChange( i, title, 'title')}}
                  />
                <EditableField
                  isLanding={this.props.isLanding}
                  tag="p"
                  field={seuil.text ?? `Texte ≥ ${seuil.seuil}%`}
                  isEditor={this.props.isEditor}
                  type="categories"
                  key={`categoriesText_${cat.key}_${this.state.categoriesIndex[cat.key]}`}
                  onInputChange={(text) => { return this.handleCategorySeuilChange( i, this.state.categoriesIndex[cat.key], text, 'text')}}
                  />
              </div>
            </div>
          )
        })}
      </MaskQuizz>

    return (
      <div className="format_quizz format_diag formatBox">
        {this.state.viewResult === true ? (
          <>
            <div className="format_wrapper quizzLeftTransition">
              <div></div>
              <div>
                <div className="main_wrapper">
                  {(this.props.isEditor || this.props.format.exerciceTitle) && (
                    <EditableField
                      isLanding={this.props.isLanding}
                      tag="h1"
                      classTag="xs"
                      field={this.props.format.exerciceTitle}
                      isEditor={this.props.isEditor}
                      type="exerciceTitle"
                    />
                  )}
                  <EditableField
                    isLanding={this.props.isLanding}
                    tag="h2"
                    field={this.props.format.total[this.state.stepIndex].title ?? `Titre ≥ ${this.props.format.total[this.state.stepIndex].seuil}%`}
                    isEditor={this.props.isEditor}
                    type="total"
                    key={'totalTitle'+ this.state.stepIndex}
                    onInputChange={(title) => { return this.handleStepsChange(title, 'title', this.state.stepIndex)}}
                  />
                  <EditableArea
                    tag="div"
                    field={ this.props.format.total[this.state.stepIndex].text}
                    isEditor={this.props.isEditor}
                    isLanding={this.props.isLanding}
                    type={'total'}
                    key={'totalText'+ this.state.stepIndex}
                    onInputChange={(text) => this.handleStepsChange(text, 'text', this.state.stepIndex)}
                  />
                  {this.state.isMobile && maskQuizz}
                </div>

                <div className="btn_wrapper">
                  <Button
                    key="txt_btn_next"
                    isEditor={this.props.isEditor}
                    isLanding={this.props.isLanding}
                    btn={this.props.format.button}
                    callback={this.manageResponse}
                  />
                </div>
              </div>
              <div></div>
            </div>
            {!this.state.isMobile && maskQuizz}
          </>
        ) : (
          <div style={{
            display: 'flex',
            flexDirection : 'column',
            width: '95%',
            height: '100%',
            justifyContent: 'center',
            alignItems : 'center',
            textAlign: 'center',
          }}>
            <EditableField
              isLanding={this.props.isLanding}
              tag="h5"
              classTag="xs"
              field={this.context.values.formatDiagnostic.loadingScore[this.context.language]}
              isEditor={false}
              type="titleLoadingResult"
            />
            <div className='loader-ring'>
              <div className='loader-ring-light'></div>
              <div className='loader-ring-track'></div>
            </div>
          </div>
        )}




        {this.props.isEditor && <DiagnosticDropdown core={this}/>}
      </div>
    )
  }
}



export default FormatDiagnostic
FormatDiagnostic.contextType = LanguageContext
